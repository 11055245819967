header + div#content {
    padding-top: 100px;
}

hr {
    clear: both;
    margin: 16px 0;
}

ol.breadcrumb-nav {
    list-style: none;
    overflow: hidden;
    margin: 0px;
    padding: 0px 10px 0px 10px;

    li {
        float: left;
    }

    li::after {
        content: "»";
        margin: 0px 8px;
    }

    li:last-child::after {
        content: "";
    }
}

article {
    padding: 20px 60px;
}

.button.list {
    float: right;
}

.product-description {

    border-top: 1px solid #fcd088;
    border-bottom: 1px solid #fcd088;
    padding: 16px;

    img {
        margin: 16px 16px;
        float: left;
    }

    p.button-line {
        text-align: right;

        a {
            float: inherit;
        }
    }
}

.product-description + .product-description {
    border-top: 0px;
}