/*
Theme Name: Astrid
Theme URI: http://athemes.com/theme/astrid
Author: aThemes
Author URI: http://athemes.com
Description: Astrid can help you to quickly create an awesome business website. All the features you need are waiting for you in this business theme: color options, fonts, full width header image, custom widgets, blog options and many other interesting and useful options.
Version: 1.14
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: astrid
Tags: two-columns, right-sidebar, custom-colors, custom-background, custom-header, custom-menu, featured-images, sticky-post, theme-options, threaded-comments, translation-ready

Astrid WordPress Theme, Copyright 2016 aThemes
Astrid is distributed under the terms of the GNU GPL v2 or later.

Astrid is based on Underscores http://underscores.me/, (C) 2012-2015 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

*/
body {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #656D6D;
	word-wrap: break-word;
	line-height: 1.9;
	letter-spacing: 1px;
	background-color: #F5F9F8;
	font-weight: 300;
	text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #272727;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 300;
	margin-top: 20px;
	margin-bottom: 20px;
	letter-spacing: 1px;
}

a,
a:hover {
	color: #fcd088;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}

blockquote {
	border-left: 5px solid #ebebeb;
    padding-left: 15px;
    margin-left: 0;
}
p {
	margin-bottom: 20px;
}
h4 {
	font-size: 16px;
}
hr {
	clear: both;
}

/*--------------------------------------------------------------
# Tables
--------------------------------------------------------------*/
table {
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid #e0e0e0;
}
th {
	background-color: #f7f7f7;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	padding: 10px;
	line-height: 1.42857143;
	vertical-align: top;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border: 1px solid #dddddd;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/
button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
	padding: 15px;
}
button,
.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	font-size: 14px;
	padding: 12px 30px;
	border: 1px solid #fcd088;
	color: #fcd088;
	text-transform: uppercase;
	letter-spacing: 4px;
	background-color: transparent;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
}
.button {
	-webkit-appearance: none;
}

input[type="submit"] {
	margin-top: 5px;
}
button:hover,
.button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
	background-color: #fcd088;
	text-decoration: none;
	color: #333;
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
	border-color: #aaa #bbb #bbb;
	box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	-webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
	-moz-box-sizing:    content-box;
	box-sizing:         content-box;
}

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea {
	color: #666;
	border: 1px solid #ebebeb;
	border-radius: 3px;
	font-weight: 400;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus {
	color: #111;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"] {
	padding: 13px;
}

textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	padding-left: 8px;
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;
}
select {
	border-color: #F2F2F3;
}
.centered-button,
.header-button {
	display: table;
	margin: 45px auto 0;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.main-navigation {
	display: block;
	float: right;
}

.main-navigation ul {
	list-style: none;
	margin: 0;
	padding-left: 0;
	float: right;
}

.main-navigation li {
	float: left;
	position: relative;
	padding: 10px 20px;
	font-size: 14px;
	letter-spacing: 2px;
}

.main-navigation a {
	display: inline-block;
	text-decoration: none;
	color: #fff;
	position: relative;
	padding: 3px 0;
	z-index: 11;	
}
.main-navigation li a::before,
.main-navigation li a::after {
	width: 0;
	content: '';
	position: absolute;	
	bottom: 0;
	z-index: -1;
	height: 100%;
	-webkit-transition: width 0.5s;
	transition: width 0.5s;	
}
.main-navigation li a::before {
	right: 0;
	border-top: 1px solid #fcd088;
}
.main-navigation ul ul ul { 
	top: 0 !important;
}
.main-navigation li a::after {
	left: 0;
	border-bottom: 1px solid #fcd088;
}
.main-navigation li a:hover::before,
.main-navigation li a:hover::after {
	width: 100%;
}
.main-navigation ul ul {
	float: left;
	position: absolute;
	top: 160%;
	left: -999em;
	z-index: 99999;
	background-color: #1C1E21;
}

.submenu-visible {
	top: 100% !important;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
}

.main-navigation ul ul ul {
	left: -999em;
	top: 0;
}

.main-navigation ul ul a {
	width: 200px;
	padding: 0;
}
.main-navigation ul ul a::before,
.main-navigation ul ul a::after {
	display: none;
}

.main-navigation ul ul li {

}

.main-navigation a:hover,
.main-navigation li.focus > a {
	color: #fcd088;
}

.main-navigation ul ul :hover > a,
.main-navigation ul ul .focus > a {
}

.main-navigation ul ul a:hover,
.main-navigation ul ul a.focus {
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
	left: auto;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
	left: 100%;
}

.main-navigation .current_page_item > a,
.main-navigation .current-menu-item > a,
.main-navigation .current_page_ancestor > a,
.main-navigation .current-menu-ancestor > a {
}

/* Mobile menu */

.btn-menu {
	display: none;
	text-align: right;
	font-size: 28px;
	color: #fff;
	line-height: 1;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.btn-menu .fa {
	cursor: pointer;	
}
.btn-submenu {
	position: relative;
	top: 3px;
	font-family: "FontAwesome";
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	padding: 10px;
	margin-left: 10px;
}
.btn-submenu:before {
	content: "\f107";
	color: #fff;
}
.btn-submenu.active:before {
	content: "\f106"
}
#mainnav-mobi {
	position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #202529;
    padding-left: 0;
    padding-right: 0;
}
#mainnav-mobi a {
	padding-left: 15px;
	padding-right: 15px;
	width: auto;
}
#mainnav-mobi li {
	display: block;
	width: 100%;
	clear: both;
	text-align: center;
	float: none;
	padding-left: 0;
	padding-right: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
#mainnav-mobi li a::after,
#mainnav-mobi li a::before {
	display: none;
}
#mainnav-mobi ul {
	float: none;
}
#mainnav-mobi ul ul {
	position: relative;
	top: 11px;
	left: 0;
	background-color: #171717;
}




.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
	margin: 0;
	overflow: hidden;
	padding: 30px 60px;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
	float: left;
	width: 50%;
}
.comment-navigation a,
.posts-navigation a,
.post-navigation a {
	color: #272727;
}
.comment-navigation a:hover,
.posts-navigation a:hover,
.post-navigation a:hover {
	color: #fcd088;	
	text-decoration: none;
}
.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
	float: right;
	text-align: right;
	width: 50%;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}


/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget-area .widget {
	margin: 0 0 -1px;
	padding: 30px;
	background-color: #fff;
	border: 1px solid #F2F2F3;
}
.widget ul {
	padding: 0 0 0 20px;
}
.widget li {
	padding-top: 2px;
	padding-bottom: 2px;
}
.widget .children {
	padding-left: 20px;
}
.widget-area .widget,
.widget-area .widget a {
	color: #656D6D;
}
.widget-area .widget-title {
	text-transform: uppercase;
	margin: 0 0 30px;
}
/* Make sure select elements fit in widgets. */
.widget select {
	max-width: 100%;
}
/* Search widget. */
.widget_search .search-submit {
	display: none;
}
/* Recent posts */
.widget_recent_posts ul {
	list-style: none;
	padding: 0;
}
.widget_recent_posts li {
	padding-bottom: 15px;
}
.widget_recent_posts li:last-of-type {
	padding-bottom: 0;
}
.widget_recent_posts .recent-thumb {
	padding: 0 15px 0 0;
}
.widget_recent_posts .recent-title {
	padding: 0;
}
.widget_recent_posts .post-date {
	display: block;
	font-size: 12px;
	padding: 3px;
	margin-top: 5px;
}
.widget_recent_posts .post-date .fa {
	margin-right: 6px;
}
/* Social widget */
.social-menu-widget {
	padding: 0 !important;
	margin: 0 auto;
	display: table;
	text-align: left;
}
.social-menu-widget a {
	background-color: #eaab1c;
	text-decoration: none;
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 8px;
	line-height: 40px;
	-webkit-transition: border-radius 0.4s;
	transition: border-radius 0.4s;
}
.social-menu-widget li a,
.social-menu-widget li a:hover {
	color: #fff !important;
}
.social-menu-widget a:hover {
	border-radius: 50%;
}
.social-menu-widget li {
	display: inline-block;
	list-style: none;
	line-height: 1;
	text-align: center;
}
.social-widget li a::before,
.social-menu-widget a:before {
	font-family: FontAwesome;
	color: inherit;
}
.social-menu-widget a:before {
	font-size: 20px;
}
.social-menu-widget a[href*="twitter.com"]::before {
	content: '\f099';
}
.social-menu-widget a[href*="facebook.com"]::before {
	content: '\f09a';
}
.social-menu-widget a[href*="plus.google.com"]::before {
	content: '\f0d5';
}
.social-menu-widget a[href*="google.com/+"]::before {
	content: '\f0d5';
}
.social-menu-widget a[href*="dribbble.com"]::before {
	content: '\f17d';
}
.social-menu-widget a[href*="pinterest.com"]::before {
	content: '\f231';
}
.social-menu-widget a[href*="youtube.com"]::before {
	content: '\f167';
}
.social-menu-widget a[href*="flickr.com"]::before {
	content: '\f16e';
}
.social-menu-widget a[href*="vimeo.com"]::before {
	content: '\f194';
}
.social-menu-widget a[href*="instagram.com"]::before {
	content: '\f16d';
}
.social-menu-widget a[href*="linkedin.com"]::before {
	content: '\f0e1';
}
.social-menu-widget a[href*="foursquare.com"]::before {
	content: '\f180';
}
.social-menu-widget a[href*="tumblr.com"]::before {
	content: '\f173';
}
.social-menu-widget a[href*="behance.net"]::before {
	content: '\f1b4';
}
.social-menu-widget a[href*="deviantart.com"]::before {
	content: '\f1bd';
}
.social-menu-widget a[href*="soundcloud.com"]::before {
	content: '\f1be';
}
.social-menu-widget a[href*="spotify.com"]::before {
	content: '\f1bc';
}
.social-menu-widget a[href*="weibo.com"]::before {
	content: '\f18a';
}
.social-menu-widget a[href*="xing.com"]::before {
	content: '\f168';
}
.social-menu-widget a[href*="trello.com"]::before {
	content: '\f181';
}

/* Default widgets */
.widget_recent_entries ul,
.widget_archive ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_categories ul {
	list-style: none;
	padding: 0;
}
.widget_recent_entries li::before,
.widget_archive li::before,
.widget_pages li::before,
.widget_recent_comments li::before,
.widget_categories li::before {
	font-family: Fontawesome;
	font-size: 15px;
}
.widget_recent_entries li::before {
	content: '\f040';
	margin-right: 5px;
}
.widget_archive li::before {
	content: '\f133';
	margin-right: 8px;
}
.widget_pages li::before {
	content: '\f016';
	margin-right: 8px;	
}
.widget_recent_comments li::before {
	content: '\f0e5';
	margin-right: 8px;
}
.widget_categories li::before {
	content: '\f114';
	margin-right: 8px;
}
.tagcloud a {
	border: 1px solid #41474F;
	padding: 5px;
	font-size: 13px !important;
	display: inline-block;
	margin-bottom: 8px;
	margin-right: 4px;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
	display: block;
}

.hentry {
	margin: 0;
	/* padding: 60px; */
	background-color: #fff;
	border: 1px solid #F2F2F3;
	margin-bottom: -1px;
}

.byline,
.updated:not(.published) {
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}
.entry-summary p:last-of-type {
	margin-bottom: 0;
}
.page-content,
.entry-content,
.entry-summary {
}
.entry-meta {
	margin-bottom: 30px;
	border-top: 1px solid #F2F2F3;
	border-bottom: 1px solid #F2F2F3;
	padding: 5px;
}
.entry-footer {
	margin-top: 30px;
}
.entry-footer,
.entry-footer a,
.entry-meta,
.entry-meta a {
	color: #A0A8AB;
}
.entry-meta,
.entry-footer {
	font-size: 13px;
}
.entry-footer .fa,
.entry-meta .fa {
	margin-right: 5px;
}
.entry-meta span::after {
	content: '/';
	margin: 0 15px;
}
.entry-meta span:last-of-type::after {
	content: '';
	margin: 0;
}
.tags-links a {
	display: inline-block;
	line-height: 1;
	padding: 2px 3px;
	margin-right: 5px;
	border: 1px solid;
}
.entry-thumb {
	padding-right: 30px;
}
.entry-thumb,
.post-thumb {
	-webkit-transition: opacity 0.4s;
	transition: opacity 0.4s;
}
.post-thumb:hover,
.entry-thumb:hover {
	opacity: 0.7;
}
.post-thumb {
	margin-bottom: 30px;
}
.has-post-thumbnail .entry-summary,
.entry-thumb {
	width: 50%;
	float: left;
}
.entry-thumb,
.entry-summary {
	margin-bottom: 30px;
}
.single-thumb {
	margin-bottom: 30px;
}
.read-more {
	clear: both;
}
.post-button {
	display: table;
}
.page-links {
	clear: both;
	margin: 0 0 1.5em;
}
.entry-title {
	margin-top: 0;
}
.entry-title,
.entry-title a {
	color: #272727;
}
.page .entry-title {
	margin-bottom: 30px;
}
.widget-area .widget a:hover,
.entry-meta a:hover,
.entry-footer a:hover,
.entry-title a:hover {
	color: #fcd088;
	text-decoration: none;
}
/* Masonry */
.masonry-layout {
	width: 100% !important;
	padding: 0;
}
.posts-layout.masonry {
	margin: 15px auto;
}
.masonry-layout .hentry {
	width: 350px;
	margin: 15px;
	padding: 30px;
	border: 0;
}
.masonry-layout .entry-thumb,
.masonry-layout .entry-summary {
	width: 100%;
	padding: 0;
}
.masonry-layout .entry-thumb {
	margin-bottom: 30px;
}
.masonry-layout .entry-title {
}
.masonry-layout .entry-meta {
	position: relative;
	bottom: 0;
	right: 0;
	clear: both;
}
.masonry-layout .entry-meta .read-more,
.masonry-layout .entry-meta .posted-on {
	float: none;
	display: inline-block;
}
.masonry-layout .entry-meta .posted-on {
	margin-bottom: 5px;
}
/*--------------------------------------------------------------
## Asides
--------------------------------------------------------------*/
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
	display: none;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comments-area {
	margin-bottom: 60px;
}
.comments-title {
	margin: 0;
	font-size: 22px;
	padding: 30px 60px;
	border: 1px solid #F2F2F3;
	background-color: #fff;
}
.comments-area ol {
	list-style: none;
	margin: 0;
}
.comments-area ol {
	padding: 0;
}
.comments-area .children {
	padding-left: 20px;
}
.comments-area .fn {
}
.comment-author .avatar {
	position: absolute;
}
.comment-author .fn,
.comment-metadata {
	margin-left: 80px;
}
.comment-metadata {
	font-size: 12px;
	margin-bottom: 30px;
}
.comment-body {
	padding: 30px 60px;
	border-left: 1px solid #F2F2F3;
	border-right: 1px solid #F2F2F3;
	border-bottom: 1px solid #F2F2F3;
}
.comment-edit-link {
	margin-left: 5px;
}
.children .comment-body {
	border-left: 3px solid #F2F2F3;
}
.reply {
	text-align: right;
}
.comment-reply-link {
	padding: 5px;
	background-color: #fff;
	font-size: 12px;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;	
}
a.comment-reply-link {
	text-decoration: none;
}
a.comment-reply-link:hover {
	color: #000;
	box-shadow: 0 0 1px #505050;
}
.comments-area a.url {
	text-decoration: underline;
}
.comment-metadata a,
.comments-area a.url {
	color: #444;
}
.bypostauthor {
}
.comment-respond {
	padding: 60px 60px 0;
	border-right: 1px solid #F2F2F3;
}
.comment-form .form-submit {
	margin-top: 30px;
}
.comment-form-author label {
	display: block;
}
.comment-form-email input,
.comment-form-url input,
.comment-form-author input {
	width: 100%;
	max-width: 100%;
}
.comment-form-email label,
.comment-form-url label {
	display: block;
}
.comment-form-email,
.comment-form-url {
	width: 48%;
	float: left;
}
.comment-form-email {
	margin-right: 2%; 
}
.comment-form-url {
	margin-left: 2%;
}
/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}
img {
	max-width: 100%;
	height: auto;
}
/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

.wp-caption-text {
	text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
	margin-bottom: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-caption {
	display: block;
}

/*--------------------------------------------------------------
## Widgetised
--------------------------------------------------------------*/
.home-wrapper section {
	position: relative;
	padding: 80px 0;
	margin-bottom: 0;
	clear: both;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;	
}
.atblock {
	position: relative;
	z-index: 11;
	padding-left: 30px;
	padding-right: 30px;
}
.atblock.no-container {
	padding: 0;
	max-width: 100% !important;
}
section.at-2-col {
	width: 50%;
	clear: none;
        -webkit-flex: 1 1 0;
        -ms-flex: 1 1 0;
         flex: 1 1 0;
}
/* Align columns to page center
section.at-2-col:first-of-type .atblock {
	margin-right: 0;
}
section.at-2-col:nth-of-type(2) .atblock {
	margin-left: 0;
}
*/
.at-2-col .atblock {
	width: 100%;
	max-width: 700px;
}
section.at-3-col {
	width: 33.33333333%;
	clear: none;
        -webkit-flex: 1 1 0;
        -ms-flex: 1 1 0;
         flex: 1 1 0;
}
.at-3-col .atblock {
	width: 100%;
	max-width: 500px;
}
.row-overlay {
	background-color: rgba(37, 46, 53, 0.8);
	content: '';
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
}
.multicolumn-row {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.multicolumn-row section {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/* Block titles */
.atblock .widget-title {
	font-size: 36px;
	text-align: center;
	margin: 0 0 75px;
	position: relative;
}
.atblock .widget-title::after,
.widget-title .title-decoration::before,
.widget-title .title-decoration::after {
	content: '';
	position: absolute;	
}
.widget-title .title-decoration::before,
.widget-title .title-decoration::after {
	width: 30px;
	height: 1px;
	bottom: -15px;
	background-color: rgba(92, 98, 101, 0.3);	
}
.atblock .widget-title::after {
	width: 6px;
	height: 6px;
	bottom: -18px;
	border-radius: 3px;
	background-color: #d2d8da;
	left: -webkit-calc(50% - 3px);
	left: -moz-calc(50% - 3px);
	left: calc(50% - 3px);
}
.widget-title .title-decoration::before {
	left: -webkit-calc(50% - 40px);
	left: -moz-calc(50% - 40px);
	left: calc(50% - 40px);
}
.widget-title .title-decoration::after {
	left: -webkit-calc(50% + 10px);
	left: -moz-calc(50% + 10px);	
	left: calc(50% + 10px);
}

/* Services */
.service-area {
	text-align: center;
}
.service {
	text-align: center;
	margin-bottom: 45px;
	padding: 0 15px;
	width: 32%;
	display: inline-block;
	vertical-align: top;
}
.astrid-3col:nth-of-type(3n+1) {
	clear: left;
}
.astrid-3col:last-of-type,
.astrid-3col:nth-last-of-type(2),
.astrid-3col:nth-last-of-type(3) {
	margin-bottom: 0;
}
.service-title a {
	color: #272727;
	text-decoration: none;
}
.service-title a:hover {
	color: #fcd088;
}
/* Skills */
.skill {
	margin-bottom: 15px;
}
.progress-bar {
	background-color: #e8ecee;
	height: 5px;
	position: relative;
}
.progress-animate {
	background-color: #fcd088;
	height: 5px;
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
}
.progress-visible {
	-webkit-transition: width 1.5s;
	transition: width 1.5s;
}
/* Facts */
.fact-area {
	text-align: center;
}
.fact {
	text-align: center;
	display: inline-block;
	width: 24%;
	padding: 0 15px;
	vertical-align: top;
}
.fact .fa {
	font-size: 44px;
	color: #fcd088;
}
.fact .fact-number {
	font-size: 36px;
	margin: 20px 0;
	font-family: 'Josefin Sans', sans-serif;
	border-top: 1px solid rgba(92, 98, 101, 0.3);
	border-bottom: 1px solid rgba(92, 98, 101, 0.3);
}
.fact .fact-name {
	font-size: 24px;
	line-height: 1.1;
	font-family: 'Josefin Sans', sans-serif;
}
/* Employees */
.employees-area {
	text-align: center;
}
.employee,
.blog-post {
	display: inline-block;
	width: 32%;
	margin-bottom: 45px;
	text-align: center;
	padding: 0 15px;
	vertical-align: top;
}
.employee-title {
	margin: 0 0 30px;
	padding: 15px;
	background-color: #202529;
}
.employee-title a {
	color: #fff;
}
.employee-title a:hover {
	color: #fcd088;
	text-decoration: none;
}
/* Projects */
.project {
	width: 25%;
	float: left;
}
.project:nth-of-type(4n+1) {
	clear: left;
}
.project-thumb {
	position: relative;
	overflow: hidden;	
}
.project-thumb::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background-color: rgba(37, 46, 53, 0.8);
	-webkit-transition: width 0.5s;
	transition: width 0.5s;
}
.project-title {
	position: relative;
	width: 100%;
	text-align: center;
	margin: 0;
}
.project-title a {
	color: #fff;
	text-decoration: none;
}
.project-content {
	position: absolute;
	top: 0;
	right: -100%;
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	align-items: center;	
	-webkit-transition: right 0.5s;
	transition: right 0.5s;	
}
.project-thumb:hover::before {
	width: 100%;
}
.project-thumb:hover .project-content {
	right: 0;
}
.project-title a::before {
    content: '';
    width: 0;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
    position: absolute;
    left: 0;
    top: -5px;    
    margin-left: 20%;
	-webkit-transition: width 0.5s 0.5s;
	transition: width 0.5s 0.5s;	    
}
.project-title a::after {
    content: '';
    width: 0;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
    position: absolute;
    right: 0;
    bottom: -5px;    
    margin-right: 20%;
	-webkit-transition: width 0.5s 0.5s;
	transition: width 0.5s 0.5s;	    
}
.project-thumb:hover .project-title a::before,
.project-thumb:hover .project-title a::after {
	width: 60%;
}
/* Testimonials */
.testimonial-area {
	text-align: center;
}
.testimonial {
	display: inline-block;
	width: 32%;
	margin-bottom: 45px;
	text-align: left;
	padding: 0 15px;
	vertical-align: top;	
}
.testimonial-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #F2F2F3;
	padding: 30px;
}
.testimonial svg {
	margin-bottom: 10px;
	fill: #fff;
	stroke: #F2F2F3;
	stroke-width: 1.05;
	position: relative;
	top: -1px;
	left: 30px;
}
.testimonial-content p:last-of-type {
	margin: 0;
}
.testimonial-thumb {
	max-width: 100px;
}
.testimonial-title {
	margin-bottom: 0;
}
.testimonial-title a {
	color: #272727;
	text-decoration: none;
}
.testimonial-title a:hover {
	color: #fcd088;
}
/* Clients */
.client-area {
	text-align: center;
}
.client {
	display: inline-block;
	width: 24%;
	margin-bottom: 45px;
	padding: 0 30px;
	vertical-align: top;	
}
.client:nth-of-type(3n+1) {
	clear: left;
}
.client:last-of-type,
.client:nth-last-of-type(2),
.client:nth-last-of-type(3),
.client:nth-last-of-type(4) {
	margin-bottom: 0;
}
/*--------------------------------------------------------------
## Structure
--------------------------------------------------------------*/
.site-title {
	font-size: 36px;
	margin: 0;
	letter-spacing: 1px;
	line-height: 1.1;
}
.site-title a,
.site-title a:hover {
	color: #fff;
	text-decoration: none;
}
.site-description {
	margin: 15px 0 0;
	font-size: 14px;
    color: #BDBDBD;
}
.site-description:empty {
	margin: 0;
}

.site-content {
	/*
	margin-top: 60px;
	margin-bottom: 60px;
	*/
}
.site-content > .container {
	padding: 0;
	background-color: #fff;
	border-left: 1px solid #F2F2F3;
	border-right: 1px solid #F2F2F3;
	border-bottom: 1px solid #F2F2F3;
}
body.page-template-page_widgetized {
	background-color: #fff;
}
.page-template-page_widgetized .site-content {
	margin-top: 0;
	margin-bottom: 0;
}
.site-logo {
	max-width: 100%;
	max-height: 80px;
}
.custom-logo-link {
	display: block;
}
.custom-logo-link img {
	display: block;
    max-height: 90px;
    margin: 0 auto;
}
.content-area {
	width: 811px;
	float: left;
	margin-left: -1px;
}
.fullwidth,
.error404 .content-area,
.fullwidth-single .content-area {
	width: 100% !important;
}
.error404 .content-area {
	padding: 30px;
}
.error404 .content-area .page-title {
	padding: 0;
}
.no-results.not-found {
	padding: 30px;
}
.no-results.not-found .page-title {
	padding: 0;
}
.fullwidth-single .content-area {
	margin-left: 0;
}
.widget-area {
	width: 360px;
	float: left;
	margin-left: -1px;
	margin-right: -1px;
}
.page-header .page-title {
	padding: 0 60px;
}
.page-header .page-title .fa {
	margin-right: 15px;
	color: #fcd088;
}

/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/
.site-header {
	padding: 15px 0;
	width: 100%;
	z-index: 999;
	position: relative;
	background-color: #202529;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;	
	border-bottom: 1px solid rgba(255,255,255,0.1);
}
.site-header.has-header,
.site-header.has-video {
	background-color: transparent;
}
.site-header.header-scrolled {
	background-color: rgba(32, 37, 41, 0.9);
	padding: 5px 0;
	border-bottom: 1px solid transparent;
}
.site-header .container {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}
.header-clone {
	height: 83px;
}
/* Header image */
.header-image {
	position: relative;
	text-align: center;
}
.header-image::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(37, 46, 53, 0.9);
}
.header-info {
	width: 100%;
	position: absolute;
	top: 35%;
	text-align: center;
	z-index: 11;
}
.header-text {
	margin: 0 auto;
	color: #fff;
	font-size: 76px;
	display: table;
	text-transform: uppercase;
	letter-spacing: 5px;
}
.header-subtext {
	color: #fff;
	display: table;
	margin: 0 auto 30px;
	padding: 0 15px 30px;
	font-size: 24px;
	border-bottom: 1px solid rgba(255,255,255,0.1);
	letter-spacing: 5px;
}
.small-header {
	display: none;
}
/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.footer-widgets,
.site-footer,
.footer-info {
	background-color: #202529;
	clear: both;
}
.footer-widgets {
	padding: 60px 0;
	border-bottom: 1px solid rgba(255,255,255,0.08);
}
.footer-widgets,
.footer-info,
.site-footer,
.footer-widgets a,
.footer-info a,
.site-footer a {
	color: #a3aaaa;
}
.site-footer a:hover,
.footer-info a:hover,
.footer-widgets a:hover {
	text-decoration: none;
	color: #fcd088;
}
.footer-widgets .widget-title {
	margin: 0 0 30px;
	color: #fff;
}
.footer-widgets .widget {
	margin: 25px 0;
}
.sidebar-column {
	padding: 0 20px;
}
.site-footer {
	padding-top: 30px;
	padding-bottom: 30px;
}
.site-copyright {
	text-align: right;
	padding-right: 30px;
}

/* Footer nav */
.site-copyright,
.footer-navigation {
	width: 50%;
	float: left;
}
.footer-navigation ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer-navigation li {
	display: inline-block;
	padding: 0 15px;
}
/* Footer branding */
.footer-info {
	border-bottom: 1px solid rgba(255,255,255,0.08);	
}
.footer-branding {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;		
	width: 30%;
	float: left;
	text-align: center;
	padding: 30px 30px 15px 15px;
}
.footer-logo {
	max-height: 100px;
	width: auto;
	max-width: 100%;
}
/* Footer contact */
.footer-contact {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;    
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;	
	width: 70%;
	padding: 60px 15px;
	border-left: 1px solid rgba(255,255,255,0.08);
}
.footer-info .container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.footernoFlex {
	float: left;
}
.footer-contact-block {
	display: inline-block;
	text-align: center;
	width: 33%;
	padding: 0 20px;
}
.footer-contact-block .fa {
	font-size: 22px;
}
.footer-contact-block span {
	display: block;
	border-top: 1px solid rgba(255,255,255,0.08);
	margin-top: 10px;
	padding-top: 10px;
}

.img-circle {
	border-radius: 50%;
}

/*--------------------------------------------------------------
## Preloader
--------------------------------------------------------------*/
.preloader {
	background-color: #fff;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.preloader-hidden {
	opacity: 0;
}
.preloader-inner {
	position: absolute;
	margin: auto;
	top: 50%;
	left: 0;
	right: 0;
	width: 88px;
}
.preloader-inner ul {
	margin: 0;
	list-style: none;
	width: 88px;
	height: 63px;
	position: relative;
	padding: 0;
	height: 10px;
}
.preloader-inner ul li {
	position: absolute;
	width: 2px;
	height: 0;
	background-color: #fcd088;
	bottom: 0;
}
.preloader-inner li:nth-child(1) {
	left: 0;
	animation: preloader1 1.15s ease infinite 0;
	-ms-animation: preloader1 1.15s ease infinite 0;
	-webkit-animation: preloader1 1.15s ease infinite 0;
}
.preloader-inner li:nth-child(2) {
	left: 15px;
	animation: preloader2 1.15s ease infinite 0.12s;
	-ms-animation: preloader2 1.15s ease infinite 0.12s;
	-webkit-animation: preloader2 1.15s ease infinite 0.12s;
}
.preloader-inner li:nth-child(3) {
	left: 29px;
	animation: preloader1 1.15s ease-in-out infinite 0.23s;
	-ms-animation: preloader1 1.15s ease-in-out infinite 0.23s;
	-webkit-animation: preloader1 1.15s ease-in-out infinite 0.23s;
}
.preloader-inner li:nth-child(4) {
	left: 44px;
	animation: preloader2 1.15s ease-in infinite 0.35s;
	-ms-animation: preloader2 1.15s ease-in infinite 0.35s;
	-webkit-animation: preloader2 1.15s ease-in infinite 0.35s;
}
.preloader-inner li:nth-child(5) {
	left: 58px;
	animation: preloader1 1.15s ease-in-out infinite 0.46s;
	-ms-animation: preloader1 1.15s ease-in-out infinite 0.46s;
	-webkit-animation: preloader1 1.15s ease-in-out infinite 0.46s;
}
.preloader-inner li:nth-child(6) {
	left: 73px;
	animation: preloader2 1.15s ease infinite 0.58s;
	-ms-animation: preloader2 1.15s ease infinite 0.58s;
	-webkit-animation: preloader2 1.15s ease infinite 0.58s;
}
@keyframes preloader1 {
	0% {
		height: 10px;
	}
	50% {
		height: 49px;
	}
	100% {
		height: 10px;
	}
}
@-ms-keyframes preloader1 {
	0% {
		height: 10px;
	}
	50% {
		height: 49px;
	}
	100% {
		height: 10px;
	}
}
@-webkit-keyframes preloader1 {
	0% {
		height: 10px;
	}
	50% {
		height: 49px;
	}
	100% {
		height: 10px;
	}
}
@keyframes preloader2 {
	0% {
		height: 19px;
	}
	50% {
		height: 63px;
	}
	100% {
		height: 19px;
	}
}
@-ms-keyframes preloader2 {
	0% {
		height: 19px;
	}
	50% {
		height: 63px;
	}
	100% {
		height: 19px;
	}
}
@-webkit-keyframes preloader2 {
	0% {
		height: 19px;
	}
	50% {
		height: 63px;
	}
	100% {
		height: 19px;
	}
}

/*--------------------------------------------------------------
## Woocommerce
--------------------------------------------------------------*/
.woocommerce .content-area {
	float: right;
	border-top: 1px solid #F2F2F3;
}
.woocommerce .widget-area {
	float: left;
}
.woocommerce .content-area {
	padding: 60px;
}
.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
	margin: 0 0 15px;
	padding: 15px;
	width: 33.3333%;
	text-align: center;
}
.woocommerce .products .star-rating {
	margin: 10px auto;
	width: 68px;
}
.woocommerce .star-rating {
	width: 78px;
	color: #fcd088;
}
.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order,
.woocommerce .cart .button, .woocommerce .cart input.button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce a.button,
.woocommerce div.product form.cart .button {
	display: table;
	margin: 45px auto 0;
    line-height: 1.9;
    cursor: pointer;
    position: relative;
    padding: 12px 30px;
    border: 1px solid #fcd088;
    font-weight: 300;
    border-radius: 0;
    left: auto;
    color: #fcd088;
    background-color: transparent;
}
.woocommerce .woocommerce-message {
	border-color: #fcd088;
}
.woocommerce .woocommerce-message:before {
	color: #fcd088;
}
.woocommerce #payment #place_order:hover, 
.woocommerce-page #payment #place_order:hover,
.woocommerce .cart .button:hover, .woocommerce .cart input.button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce div.product form.cart .button:hover,
.woocommerce a.button:hover {
	background-color: #fcd088;
    text-decoration: none;
    color: #333;
}
.woocommerce .cart .button, .woocommerce .cart input.button,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce div.product form.cart .button,
.woocommerce ul.products li.product .button {
	text-transform: none;
	padding: 5px 15px;
}
.woocommerce .cart .button, .woocommerce .cart input.button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce div.product form.cart .button {
	margin-top: 0;
}
.woocommerce div.product form.cart .button::before,
.woocommerce ul.products li.product .button::before {
	content: '\f07a';
	font-family: FontAwesome;
	margin-right: 5px;
}
.woocommerce ul.products li.product h3 {
	font-size: 18px;
}
.woocommerce ul.products li.product .price {
	color: #272727;
	font-size: 16px;
    font-weight: 300;
}
.woocommerce span.onsale {
	background-color: #fcd088;
}
.woocommerce ul.products li.product .onsale {
	border-radius: 0;
	margin: 0;
	top: 15px;
	right: 15px;
	line-height: 1;
    min-width: 0;
    min-height: 0;
    padding: 10px 15px;
    font-weight: 400;
    letter-spacing: 2px;
}
.woocommerce .woocommerce-breadcrumb {
	padding: 5px;
	border: 1px solid #F2F2F3;
	margin-bottom: 25px;
}
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
	padding: 15px;
}
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message,
.woocommerce nav.woocommerce-pagination ul li span.current {
	background-color: #F9F9F9;
}
.woocommerce nav.woocommerce-pagination ul,
.woocommerce nav.woocommerce-pagination ul li {
	border-color: #F2F2F3;
}
.woocommerce-result-count {
    position: relative;
    top: 15px;
}
.woocommerce .quantity .qty {
	padding: 5px;
	border: 1px solid #F2F2F3;
}
.woocommerce div.product form.cart {
    margin: 15px 0;
}
.woocommerce div.product p.price {
	color: #272727;
	font-size: 20px;
    font-weight: 300;
}
.woocommerce div.product p.price,
.woocommerce div.product .woocommerce-product-rating {
	margin-bottom: 15px;
}
.woocommerce div.product .woocommerce-tabs ul.tabs:before,
.woocommerce div.product .woocommerce-tabs ul.tabs li {
	border-color: #F2F2F3;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li:after,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active:after,
.woocommerce div.product .woocommerce-tabs ul.tabs li:before {
	display: none;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li {
	background-color: #f7f7f7;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li a {
	font-weight: 300;
}
.woocommerce table {
	border-collapse: collapse !important;
}
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
	font-size: 14px;
}
.woocommerce table.shop_table td {
	padding: 30px 15px;
}
.coupon .input-text {
	width: auto !important;
	padding: 8px !important;
}
.select2-container .select2-choice {
	border-color: #ebebeb;
}
#add_payment_method #payment,
.woocommerce-checkout #payment {
	background-color: transparent;
}
.woocommerce div.product p.price,
.woocommerce div.product span.price {
	color: #272727;
}
.woocommerce-variation-price {
	margin-bottom: 15px;
}
.woocommerce div.product form.cart .variations td.label {
    padding-top: 20px;
}
/* Core video
-------------------------------------------------------------- */
.wp-custom-header video {
	display: block;
	height: auto;
	max-width: 100%;
	width: 100%;
}
.wp-custom-header iframe {
	vertical-align: top;
}

.wp-custom-header {
	position: relative;
}
.wp-custom-header .wp-custom-header-video-button {
	position: absolute;
	bottom: 30px;
	right: 30px;
}

.wp-custom-header .wp-custom-header-video-button:focus {
	outline: none;
}
/*--------------------------------------------------------------
## Responsive
--------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
	.main-navigation {
		display: none;
	}	
	.mobile-nav,
	.btn-menu {
		display: block;
	}	
}
@media only screen and (max-width: 1199px) {
	.woocommerce .content-area {
		width: 610px;
		padding: 60px 30px;
	}
	.content-area {
		width: 610px;
		float: left;
	}
	.masonry-layout .hentry {
		width: 283px;
	}
	.header-text {
		font-size: 42px;
	}
	.header-info {
		top: 30%;
	}
	.header-subtext {
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	.header-button {
		margin-top: 30px;
	}
	.fact .fact-number {
		font-size: 28px;
	}
	.fact .fa {
		font-size: 36px;
	}
	.fact .fact-name {
		font-size: 18px;
	}
	.has-post-thumbnail .entry-summary,
	.entry-thumb {
		width: 100%;
	}
	.entry-thumb {
		padding-right: 0;
	}
}
@media only screen and (max-width: 1024px) {
	.main-navigation,
	.header-clone,
	.large-header {
		display: none;
	}	
	.mobile-nav,
	.small-header {
		display: block;
	}
	.site-header .container {
		display: block;
	}
	.site-branding {
		margin-bottom: 15px;
	}
	.site-branding,
	.btn-menu {
		text-align: center;
		width: 100%;
	}
	.site-header.has-single,
	.site-header.has-video,
	.site-header.has-header {
		position: relative;
		background-color: #202529;
	}
    .site-header {
        position: relative !important;
    }	
	.home-wrapper section {
		background-attachment: scroll;
	}
}
@media only screen and (max-width: 991px) {
	.woocommerce .content-area,
	.content-area,
	.widget-area {
		width: 100%;
	}	
	.masonry-layout .hentry {
		width: 330px;
	}
	.header-text {
		font-size: 22px;
	}
	.header-subtext {
		font-size: 16px;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	.header-button {
		margin-top: 20px;
	}	
	.astrid-3col {
		width: 48%;
	}
	.astrid-3col:nth-of-type(2n+1) {
		clear: left;
	}
	.astrid-3col:nth-last-of-type(3) {
		margin-bottom: 45px;
	}
	.astrid-3col:last-of-type,
	.astrid-3col:nth-last-of-type(2) {
		margin-bottom: 0;
	}
	.fact {
		width: 49%;
		margin-bottom: 45px;
	}
	.fact:nth-of-type(3) {
		clear: left;
	}
	.fact:last-of-type,
	.fact:nth-last-of-type(2) {
		margin-bottom: 0;
	}
	.multicolumn-row,
	.multicolumn-row section {
		display: block;
	}	
	section.at-2-col,
	section.at-3-col {
		width: 100%;
		display: block;
	}
	.project {
		width: 50%;
	}
	.project:nth-of-type(2n+1) {
		clear: left;
	}
	.project-title {
		font-size: 18px;
	}
	.client {
		width: 48%;
	}
	.client:nth-last-of-type(3),
	.client:nth-last-of-type(4) {
		margin-bottom: 45px;
	}
	.atblock .widget-title {
		font-size: 26px !important;
	}
	.footer-info .container {
		display: block;		
	}
	.footer-branding,
	.footer-contact {
		display: block;
		width: 100%;
		border: 0;	
		float: none;	
	}
	.site-copyright, 
	.footer-navigation {
		width: 100%;
		float: none;
		text-align: center;
		padding: 0;
	}
	.footer-navigation {
		margin-bottom: 15px;
	}
	.woocommerce .content-area,
	.hentry {
		padding: 30px;
	}	
	.site-main .comment-navigation,
	.site-main .posts-navigation,
	.site-main .post-navigation	{
		padding: 30px;
	}
}
@media only screen and (max-width: 768px) {
	.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
		clear: none;
	}
	.woocommerce ul.products li.product,
	.woocommerce-page ul.products li.product {
		width: 50%;
	}
	.woocommerce ul.products li.product:nth-of-type(2n+1),
	.woocommerce-page ul.products li.product:nth-of-type(2n+1) {
		clear: left;
	}	
}
@media only screen and (max-width: 767px) {
	.masonry-layout .hentry {
		width: 100%;
		max-width: 380px;
		margin: 0 0 15px;
	}
	.posts-layout.masonry {
		display: table;
		margin-left: auto;
		margin-right: auto;
		width: 100% !important;
		max-width: 380px;
	}
	.astrid-3col {
		width: 100%;
		margin-bottom: 30px;
	}
	.astrid-3col:nth-last-of-type(2),
	.astrid-3col:nth-last-of-type(3) {
		margin-bottom: 30px;
	}
	.fact {
		width: 100%;
	}
	.fact:nth-last-of-type(2) {
		margin-bottom: 45px;
	}
	.client:nth-last-of-type(3),
	.client:nth-last-of-type(4)	{
		margin-bottom: 30px;
	}
	.client {
		padding: 0 15px;
	}
	.footer-contact-block {
		width: 100%;
		margin-bottom: 30px;
	}
	.fluid-width-video-wrapper + #wp-custom-header-video-button {
		display: none;
	}		
}
@media only screen and (max-width: 500px) {
	.project {
		width: 100%;
	}
	.project-title {
		font-size: 16px;
	}
	.atblock .widget-title,
	h3 {
		font-size: 20px !important;
	}	
	.astrid-2col,
	.astrid-3col {
		padding-left: 0;
		padding-right: 0;
	}
	.woocommerce .content-area,
	.hentry {
		padding: 15px;
	}
	.woocommerce ul.products li.product,
	.woocommerce-page ul.products li.product {
		width: 100%;
	}
}
